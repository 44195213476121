import React from "react";
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import logo from "../../assets/images/pplogo.jpg";

function EventPdf(props) {
	return (
		<Document>			
			<Page style={{padding: '15px 50px 15px 50px', fontWeight: 700}}>
				<View style={{width: '100%', height: 50}} fixed></View>
				<View style={{paddingBottom: 30}}>
					<View style={{width: '100%', justifyContent: 'center'}}>
						<Image src={logo} style={{width: 111, height: 85}} alt="logo" />
					</View>	
					<View style={{flexDirection: 'row', width: '100%', marginTop: 40, justifyContent: 'space-between', alignItems: 'flex-end', fontSize: 12}}>			
						<View>
							<View style={{paddingVertical: 2}}><Text style={{fontSize: 15, color: '#212121'}}>Uitdraai {props.name}</Text></View>
							<View style={{marginTop: 8}}><Text style={{color: '#444444'}}>{props.exportInfo.evt}</Text></View>	
						</View>	
						<View style={{alignItems: 'flex-end'}}>
							<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.exportInfo.usr.bedrijfsnaam}</Text></View>	
							<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.exportInfo.usr.adres}</Text></View>
							<View style={{paddingVertical: 2}}><Text style={{color: '#444444'}}>{props.exportInfo.usr.postcodeplaats}</Text></View>
						</View>
					</View>		
				</View>	

				<View style={{flexDirection: 'row', color: '#555555', fontSize: 14, paddingVertical: 12, justifyContent: 'space-between'}}>
					<View style={{width: '50%'}}>
						<Text>Verkoop (excl. transactiekosten)</Text>
					</View>
				</View>			
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, paddingVertical: 12, justifyContent: 'space-between', borderTopWidth: 0.5, borderColor: '#878787'}}>
					<View style={{width: '30%'}}>
						<Text>Munten online</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {props.data.tokenonline.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, paddingVertical: 12, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Munten cash</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {props.data.tokencash.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, paddingVertical: 12, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Munten pin</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {props.data.tokenpin.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between', borderTopWidth: 0.5, borderBottomWidth: 0.5, borderColor: '#878787'}}>
					<View style={{width: '30%'}}>
						<Text>Direct pin</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {props.data.pinomzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 12, fontWeight: 900, paddingVertical: 15, justifyContent: 'space-between', borderBottomWidth: 0.5, borderColor: '#878787'}}>
					<View style={{width: '30%'}}>
						<Text>Totaal Verkoop</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {(props.data.tokenpin + props.data.tokencash + props.data.tokenonline + props.data.pinomzet).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>

				<View style={{flexDirection: 'row', color: '#555555', fontSize: 14, marginTop: 30, paddingVertical: 12, justifyContent: 'space-between', borderBottomWidth: 0.5, borderColor: '#878787'}}>
					<View style={{width: '30%'}}>
						<Text>Barren / food</Text>
					</View>
				</View>	
				{props.data.barren ? 
				<View>
					{Object.entries(props.data.barren).map((bar, index) => (
					<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, paddingVertical: 12, justifyContent: 'space-between', borderColor: '#878787'}}>
						<View style={{width: '30%'}}>
							<Text>{bar[0]}</Text>
						</View>
						<View style={{width: '20%', textAlign: 'right'}}>
							<Text>€ {bar[1].toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
						</View>
					</View>
					))}
				</View>
				:null}
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 12, paddingTop: 15, fontWeight: 900, justifyContent: 'space-between', borderTopWidth: 0.5, borderColor: '#878787'}}>
					<View style={{width: '30%'}}>
						<Text>Totaal Baromzet</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {props.data.baromzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 11, marginTop: 6, paddingVertical: 15, justifyContent: 'space-between'}}>
					<View style={{width: '30%'}}>
						<Text>Verschil verkoop/baromzet</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {((props.data.tokenpin + props.data.tokencash + props.data.tokenonline + props.data.pinomzet) - props.data.baromzet).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>


				<View style={{flexDirection: 'row', color: '#555555', fontSize: 14, marginTop: 30, paddingVertical: 12, justifyContent: 'space-between', borderBottomWidth: 0.5, borderColor: '#878787'}}>
					<View style={{width: '30%'}}>
						<Text>Eigen gebruik</Text>
					</View>
				</View>	
				<View style={{flexDirection: 'row', color: '#212121', fontSize: 10, paddingVertical: 15, justifyContent: 'space-between'}}>
					<View style={{width: '40%'}}>
						<Text>Opgewaardeerd via oplaadpunt</Text>
					</View>
					<View style={{width: '20%', textAlign: 'right'}}>
						<Text>€ {props.data.eigengebruik.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text>
					</View>
				</View>

				<View style={{width: '100%', height: 25}} fixed></View>
			</Page>			
		</Document>
	);
}

export default EventPdf;