import React from "react";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import PerUurPdf from '../../components/pdf/peruurpdf';
import { rtdb } from '../../components/firebase/firebase';
import { observer, inject } from 'mobx-react';

@inject('observableStore')
@observer

class exportEvent extends React.Component {

    render() {return (<div></div>)}
	
	generateEventPdf = (info, start, end, name) => {	
		var startDate = new Date(start);
		var endDate = new Date(end);
		const formatedTime = startDate.getFullYear() + '-' + ("0" + (startDate.getMonth() + 1)).slice(-2) + '-' + ("0" + startDate.getDate()).slice(-2);
		const formatedTimeEnd = endDate.getFullYear() + '-' + ("0" + (endDate.getMonth() + 1)).slice(-2) + '-' + ("0" + endDate.getDate()).slice(-2);
		var statsRef = rtdb.ref('/stats/' + this.props.observableStore.uid + '/' + this.props.observableStore.currEvent.ID)
		var tokenonline = 0;
		var tokenoffline = 0;
		var tokencash = 0;
		var tokenpin = 0;
		var eigengebruik = 0;
		var baromzet = 0;
		var pinomzet = 0;
		var barren = {};
		statsRef.orderByKey().startAt(formatedTime).endAt(formatedTimeEnd).once('value', (snap) => {
			for(const [key, value] of Object.entries(snap.val())){
				if(value.tokenverkoop){
					if(formatedTime === formatedTimeEnd){ 
						for(const [hourkey, hourvalue] of Object.entries(value.tokenverkoop.omzetperuur)){
							if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours() && parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){tokenonline = tokenonline + hourvalue}
						}
					}
					else if(key === formatedTime || key === formatedTimeEnd){ 
						for(const [hourkey, hourvalue] of Object.entries(value.tokenverkoop.omzetperuur)){
							if(key === formatedTime){
								if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours()){tokenonline = tokenonline + hourvalue}
							}
							if(key === formatedTimeEnd){
								if(parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){tokenonline = tokenonline + hourvalue}
							}
						}
					}
					else{
						tokenonline = tokenonline + (value.tokenverkoop.omzet ? value.tokenverkoop.omzet : 0);
					}
				}
				if(value.oplaadpunten){
					Object.keys(value.oplaadpunten).forEach(oplaadpunt => {
						if(value.oplaadpunten[oplaadpunt].cash){
							if(formatedTime === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.oplaadpunten[oplaadpunt].cash)){
									if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours() && parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){tokencash = tokencash + hourvalue}
								}
							}
							else if(key === formatedTime || key === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.oplaadpunten[oplaadpunt].cash)){
									if(key === formatedTime){
										if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours()){tokencash = tokencash + hourvalue}
									}
									if(key === formatedTimeEnd){
										if(parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){tokencash = tokencash + hourvalue}
									}
								}
							}
							else{
								tokencash = tokencash + (value.oplaadpunten[oplaadpunt].cash ? Object.values(value.oplaadpunten[oplaadpunt].cash).reduce((sum, num) => sum + num) : 0);
							}
						}
						if(value.oplaadpunten[oplaadpunt].pin){
							if(formatedTime === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.oplaadpunten[oplaadpunt].pin)){
									if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours() && parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){tokenpin = tokenpin + hourvalue}
								}
							}
							else if(key === formatedTime || key === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.oplaadpunten[oplaadpunt].pin)){
									if(key === formatedTime){
										if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours()){tokenpin = tokenpin + hourvalue}
									}
									if(key === formatedTimeEnd){
										if(parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){tokenpin = tokenpin + hourvalue}
									}
								}
							}
							else{
								tokenpin = tokenpin + (value.oplaadpunten[oplaadpunt].pin ? Object.values(value.oplaadpunten[oplaadpunt].pin).reduce((sum, num) => sum + num) : 0);
							}
						}
						if(value.oplaadpunten[oplaadpunt].eigengebruik){
							if(formatedTime === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.oplaadpunten[oplaadpunt].eigengebruik)){
									if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours() && parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){eigengebruik = eigengebruik + hourvalue}
								}
							}
							else if(key === formatedTime || key === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.oplaadpunten[oplaadpunt].eigengebruik)){
									if(key === formatedTime){
										if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours()){eigengebruik = eigengebruik + hourvalue}
									}
									if(key === formatedTimeEnd){
										if(parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){eigengebruik = eigengebruik + hourvalue}
									}
								}
							}
							else{
								eigengebruik = eigengebruik + (value.oplaadpunten[oplaadpunt].eigengebruik ? Object.values(value.oplaadpunten[oplaadpunt].eigengebruik).reduce((sum, num) => sum + num) : 0);
							}
						}
					});	
				}
				if(value.pinverkoop){
					if(formatedTime === formatedTimeEnd){ 
						for(const [hourkey, hourvalue] of Object.entries(value.pinverkoop.omzetperuur)){
							if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours() && parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){pinomzet = pinomzet + hourvalue}
						}
					}
					else if(key === formatedTime || key === formatedTimeEnd){ 
						for(const [hourkey, hourvalue] of Object.entries(value.pinverkoop.omzetperuur)){
							if(key === formatedTime){
								if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours()){pinomzet = pinomzet + hourvalue}
							}
							if(key === formatedTimeEnd){
								if(parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){pinomzet = pinomzet + hourvalue}
							}
						}
					}
					else{
						pinomzet = pinomzet + (value.pinverkoop.omzet ? value.pinverkoop.omzet : 0);
					}
				}
				if(value.afhaalpunten){
					Object.keys(value.afhaalpunten).forEach(afhaalpunt => {
						var ahp = value.afhaalpunten[afhaalpunt];
						if(ahp.omzetperuur){
							if(!barren[ahp.naam]){barren[ahp.naam] = 0}
							if(formatedTime === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(ahp.omzetperuur)){
									if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours() && parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){
										barren[ahp.naam] = barren[ahp.naam] + hourvalue;
										baromzet = baromzet + hourvalue;
									}
								}
							}
							else if(key === formatedTime || key === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(ahp.omzetperuur)){
									if(key === formatedTime){
										if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours()){
											baromzet = baromzet + hourvalue;
											barren[ahp.naam] = barren[ahp.naam] + hourvalue;
										}
									}
									if(key === formatedTimeEnd){
										if(parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){
											baromzet = baromzet + hourvalue;
											barren[ahp.naam] = barren[ahp.naam] + hourvalue;
										}
									}
								}
							}
							else{
								for(const [hourkey, hourvalue] of Object.entries(ahp.omzetperuur)){
									barren[ahp.naam] = barren[ahp.naam] + hourvalue;
									baromzet = baromzet + hourvalue;
								}
							}
						}
					});	
				}
			}
			var data={tokenonline: tokenonline, tokenoffline: tokenoffline, tokencash: tokencash, tokenpin: tokenpin, eigengebruik: eigengebruik, baromzet: baromzet, pinomzet: pinomzet, barren: barren}
			this.generatePDFDocument(data,info,name)
		});
	}

	generatePDFDocument = async (data,info,name) => {
		const blob = await pdf(<PerUurPdf data={data} name={name} exportInfo={info} />).toBlob();
		saveAs(blob, 'Partypay_Export_' + name + '.pdf');
	};


}

export default exportEvent;



